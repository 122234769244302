<template>
    <div class="projectDetail">
        <div class="detail-bg">
            <div class="container">
                <div class="title">
                    <span></span>
                    <h4>{{ row.info && row.info.title }}</h4>
                </div>
                <el-breadcrumb separator-class="el-icon-arrow-right" class="detail-breadcrumb">
                    <el-breadcrumb-item :to="{ path: '/home' }">{{ $t('m.10001') }}</el-breadcrumb-item>
                    <el-breadcrumb-item :to="{ path: '/project' }">{{ $t('m.10003') }}</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ row.info && row.info.title }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
        <main class="main">
            <div class="container">
                <div class="item">
                    <div class="content">
                        <div class="left">
                            <div class="content-item" v-html="row.info && row.info.content"></div>
                            <address class="address">
                                <p>{{ $t('m.10046') }}：{{ row.info && row.info.phone }}</p>
                                <p v-if="row.info && row.info.website">
                                    <a target="_blank" :href="row.info && row.info.website"> {{ $t('m.10048') }}：{{ row.info && row.info.website }}</a>
                                </p>
                                <p>{{ $t('m.10047') }}：{{ row.info && row.info.email }}</p>
                            </address>
                        </div>
                    </div>
                </div>
            </div>
        </main>
        <!-- 上一篇 下一篇按钮 -->
        <div class="button">
            <div :class="['pre', 'pointer', { disable: !row.prevId }]" @click="goPreProject">
                <i class="iconfont icon-arrowLeft"></i>
                <span>{{ $t('m.10067') }}</span>
            </div>
            <div :class="['next', 'pointer', { disable: !row.nextId }]" @click="goNextProject">
                <span>{{ $t('m.10068') }}</span>
                <i class="iconfont icon-arrowRight"></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            row: {},
            _id: '',
        };
    },
    computed: {
        lang() {
            return this.$i18n.locale || 'zh_HK';
        },
    },
    watch: {
        $route: function(to, form) {
            window.scrollTo(0, 0);
            this.getInvestDetail(to.query.id);
        },
    },
    created() {
        window.scrollTo(0, 0);
        this.getInvestDetail(this.$route.query.id);
    },
    methods: {
        getInvestDetail(id) {
            this.$api
                .getInvestDetail({
                    id,
                })
                .then((res) => {
                    if (res.code == 200) {
                        this.row = res.data;
                    }
                });
        },
        goPreProject() {
            if (!this.row.prevId) {
                return;
            }
            this.$router.push({
                path: '/project/detail',
                query: { id: this.row.prevId },
            });
        },
        goNextProject() {
            if (!this.row.nextId) {
                return;
            }
            this.$router.push({
                path: '/project/detail',
                query: { id: this.row.nextId },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
@media (max-width: 768px) {
    .projectDetail {
        padding-top: vv(87);
        padding-bottom: vv(50);
        .detail-bg {
            width: 100%;
            height: vv(116);
            background: url(~@/assets/imgs/detail_bg_m.png) center no-repeat;
            background-size: cover;
            .container {
                position: relative;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .title {
                span {
                    display: none;
                }
                h4 {
                    text-align: center;
                    font-size: vv(20);
                    font-weight: 700;
                    color: #ffffff;
                }
            }
            .detail-breadcrumb {
                width: vv(351);
                display: flex;
                overflow: auto;
                position: absolute;
                left: vv(12);
                top: vv(-30);
                /deep/ .el-breadcrumb__item {
                    flex-shrink: 0;
                }
                /deep/ .el-breadcrumb__inner.is-link {
                    font-weight: normal;
                    color: #666;
                    &:hover {
                        color: #333;
                    }
                }
                /deep/ .el-breadcrumb__separator {
                    color: #666;
                }
                /deep/ .el-breadcrumb__inner {
                    font-size: vv(12);
                    color: #666;
                    &:hover {
                        color: #333;
                    }
                }
            }
        }
        .main {
            .item {
                padding-top: vv(20);
                .banner {
                    height: vv(203);
                    margin-bottom: vv(20);
                    img,
                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
                .content {
                    .right {
                        display: none;
                    }
                    .left {
                        width: 100%;
                        .content-item {
                            // margin-bottom: vv(10);
                            h5 {
                                text-align: center;
                                font-weight: 700;
                                font-size: vv(16);
                                color: #333;
                                margin-bottom: vv(10);
                            }
                            div {
                                margin-bottom: vv(20);
                                p {
                                    font-size: vv(12);
                                    color: #333;
                                }
                                img {
                                    width: 100%;
                                    height: vv(407);
                                    margin-top: vv(5);
                                }
                            }
                        }
                        .address {
                            font-size: vv(14);
                            color: #999;
                        }
                    }
                }
            }
        }
        .button {
            margin-top: vv(40);
            display: flex;
            justify-content: center;
            .pre {
                margin-right: vv(20);
                &::after {
                    right: -1px;
                }
            }
            .next {
                &::after {
                    left: -1px;
                }
            }
            .pre,
            .next {
                position: relative;
                min-width: vv(66);
                height: vv(21);
                line-height: vv(19);
                display: flex;
                justify-content: space-between;
                padding: 0 vv(10);
                font-size: vv(9);
                color: #009aa4;
                border: 1px solid #009aa4;
                background-color: transparent;
                transition: all 0.3s;
                &::after {
                    content: '';
                    position: absolute;
                    top: -1px;
                    height: vv(21);
                    width: 0;
                    background-color: #009aa4;
                    transition: width 0.3s;
                    z-index: -1;
                }
                &:hover {
                    color: #fff;
                    &::after {
                        width: vv(66);
                    }
                }
                i {
                    font-size: vv(12);
                }
                &.disable {
                    color: #ccc;
                    border: 1px solid #cccccc;
                    &:hover {
                        color: #ccc;
                        &::after {
                            width: 0;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 769px) {
    .projectDetail {
        padding-top: 68px;
        padding-bottom: 100px;
        .detail-bg {
            width: 100%;
            height: 232px;
            background: url(~@/assets/imgs/detail_bg.png) center no-repeat;
            background-size: cover;
            display: flex;
            justify-content: center;
            padding: 0 30px;
            .container {
                position: relative;
                height: 100%;
            }
            .title {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-top: 89px;
                span {
                    width: 83px;
                    height: 2px;
                    background: #ffffff;
                    margin-right: 10px;
                }
                h4 {
                    font-size: 40px;
                    font-family: Alibaba-PuHuiTi-M, Alibaba-PuHuiTi;
                    font-weight: normal;
                    color: #ffffff;
                }
            }
            .detail-breadcrumb {
                position: absolute;
                right: 0;
                bottom: 20px;
                /deep/ .el-breadcrumb__inner.is-link {
                    font-weight: normal;
                    color: rgba($color: #fff, $alpha: 0.5);
                    &:hover {
                        color: rgba($color: #fff, $alpha: 1);
                    }
                }
                /deep/ .el-breadcrumb__separator {
                    color: #fff;
                }
                /deep/ .el-breadcrumb__inner {
                    font-size: 16px;
                    color: rgba($color: #fff, $alpha: 0.5);
                    &:hover {
                        color: rgba($color: #fff, $alpha: 1);
                    }
                }
            }
        }
        .main {
            display: flex;
            justify-content: center;
            padding: 0 30px;
            .item {
                padding-top: 60px;
                .banner {
                    height: 684px;
                    margin-bottom: 60px;
                    img,
                    video {
                        width: 100%;
                        height: 100%;
                    }
                }
                .content {
                    display: flex;
                    .right {
                        width: 331px;
                        margin-left: 40px;
                    }
                    .left {
                        flex: 1;
                        .content-item {
                            margin-bottom: 40px;
                            font-size: 24px;
                            color: #333;
                            h5 {
                                text-align: center;
                                font-weight: 600;
                                font-size: 28px;
                                color: #333;
                                margin-bottom: 20px;
                            }
                            img {
                                display: none;
                            }
                        }
                        .address {
                            font-size: 23px;
                            color: #999;
                            a {
                                color: #999;
                            }
                        }
                    }
                }
            }
        }
        .button {
            margin-top: 40px;
            display: flex;
            justify-content: center;
            .pre {
                margin-right: 40px;
                &::after {
                    right: -2px;
                }
            }
            .next {
                &::after {
                    left: -2px;
                }
            }
            .pre,
            .next {
                position: relative;
                min-width: 132px;
                height: 42px;
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 0 20px;
                font-size: 18px;
                color: #009aa4;
                border: 2px solid #009aa4;
                background-color: transparent;
                transition: all 0.3s;
                &::after {
                    content: '';
                    position: absolute;
                    top: -2px;
                    height: 42px;
                    width: 0;
                    background-color: #009aa4;
                    transition: width 0.3s;
                    z-index: -1;
                }
                &:hover {
                    color: #fff;
                    &::after {
                        width: 132px;
                    }
                }
                i {
                    font-size: 24px;
                }
                &.disable {
                    color: #ccc;
                    border: 2px solid #cccccc;
                    &:hover {
                        color: #ccc;
                        &::after {
                            width: 0;
                        }
                    }
                }
            }
        }
    }
}
@media (min-width: 992px) {
    .projectDetail {
        .container {
            width: 992px;
        }
    }
}
@media (min-width: 1200px) {
    .projectDetail {
        .container {
            width: 1200px;
        }
    }
}
</style>
